.logo-container {
    z-index: 0;
    width: 400px;
    position: absolute;
    top: 100px;
    right: 10%;
    left: auto;
    margin: auto;

    .solid-logo {
        position: absolute;
        opacity: 0.3;
        width: 70%;
        animation: rotateIn 1.25s linear both;
        animation-delay: 1.4s;
    }
}

@media screen and (max-width: 1050px) {
    .solid-logo {
        display: none;
    }
}